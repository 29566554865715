import React from 'react';
import "./Footer.css";
import { Link } from 'react-scroll'; // Import Link from react-scroll for navigation
import { FaInstagram } from "react-icons/fa6"; // Import the Instagram icon

const Footer = () => {
  return (
    <footer id='footer'>
      <div className="section__wrapper">
        {/* Navigation links matching the navbar */}
        <ul className="nav__link__container">
          <Link
            activeClass='active'
            className='tab__item name'
            to='header' // Match the "Accueil" section
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Accueil
          </Link>

          <Link
            activeClass='active'
            className='tab__item name'
            to='about' // Match the "À propos" section
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            À propos
          </Link>

          <Link
            activeClass='active'
            className='tab__item name'
            to='services' // Match the "Services" section
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Services
          </Link>

          <Link
            activeClass='active'
            className='tab__item name'
            to='contact' // Match the "Contacter" section
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Contacter
          </Link>
        </ul>

        {/* Instagram only social handle */}
        <div className="social__handles__container">
          <a
            href="https://www.instagram.com/yencommunaute?utm_source=ig_web_button_share_sheet&igshid=ZDNlZDc0MzIxNw=="
            className='icon__container social__handles'
            target='_blank'
            rel="noopener noreferrer"
          >
            <FaInstagram /> {/* Instagram icon */}
          </a>
        </div>

        <div className="copyright__container">
          <h3>Copyright &copy; All right reserved YEN | 2024</h3>
          <p className="text__muted">Built with love by Caleb Paradis</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
