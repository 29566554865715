import React, { useEffect, useRef } from 'react'
import "./About.css"
import { profile2 } from '../../images'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'


const About = () => {
  const container = useRef(null)
  gsap.registerPlugin(ScrollTrigger)
  useEffect(()=>{
    const el = container.current;
    gsap.fromTo(".about__container",{
      scale:0.7
    },
    {
      scale:1,
      scrollTrigger:{
        trigger:el,
        scrub:true,
      }
    }
  )
  },[])
  return (
    <section id='about' ref={container}>
      <div className="section__wrapper about__container">
        <div className="me__container blur-effect">
          <div className="photo__container">
            <img src={profile2} alt="" />
          </div>
        </div>
        <div className="section__header">
          <h2 className="primary__title">À propos</h2>
          <h1 className="title">de <span className="color__primary">YEN</span></h1>
          <p className="text__muted description">
          Nous sommes une communauté engagée de jeunes entrepreneurs, unis par une vision commune : promouvoir l’innovation, le réseautage, le développement personnel et professionnel au sein de notre région. Notre objectif est de créer un écosystème dynamique où chacun peut partager ses connaissances, s'entraider et croître.

À travers notre réseau, nous facilitons des connexions stratégiques et encourageons les collaborations. Nous croyons fermement que l’investissement collectif et l’échange de compétences sont les clés de la réussite entrepreneuriale.

Que vous soyez un entrepreneur d’expérience ou un jeune professionnel qui désire maximiser son potentiel, notre communauté offre un environnement propice à l’épanouissement de vos ambitions. Ensemble, nous bâtissons un avenir solide, soutenu par l’entraide et la passion pour l’entrepreneuriat.
          </p>
        </div>
      </div>
    </section>
  )
}

export default About
