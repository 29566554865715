import React, { useEffect, useRef } from 'react';
import "./Services.css";
import { FaHandshake, FaUsers, FaNetworkWired } from 'react-icons/fa'; // Imported new social icons
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Services = () => {
  const container = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const el = container.current;
    gsap.fromTo(
      '.service__head',
      {
        opacity: 0,
      },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: el,
        },
      }
    );

    gsap.fromTo(
      '.service',
      {
        y: -50,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: el,
          start: '-100% bottom',
          end: 'bottom 20%',
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <section id='services' ref={container}>
      <div className="section__wrapper services__wrapper">
        <div className="section__header center">
          <h2 className="primary__title">Services</h2>
          <p className="text__muted description">Ce que nous offrons</p>
        </div>

        <div className="services__group">
          <article className="service">
            <div className="service__top">
              <div className="icon__container">
                <FaUsers className="icon" /> {/* Changed icon to FaUsers for gatherings */}
              </div>
              <h3 className="title">Regroupements mensuels</h3>
            </div>
            <div className="service__middle">
              <p className="text__muted description">
                Nous organisons des rencontres mensuelles pour discuter de divers sujets essentiels du monde des affaires, comme les marchés financiers, la politique, l’économie, et les ventes. Ces discussions sont une opportunité pour nos membres d'échanger des idées et de s’enrichir mutuellement.
              </p>
            </div>
          </article>
          {/* End Regroupements mensuels */}

          <article className="service" style={{ '--color-primary': 'var(--color-success)' }}>
            <div className="service__top">
              <div className="icon__container">
                <FaHandshake className="icon" /> {/* Changed icon to FaHandshake for professional interactions */}
              </div>
              <h3 className="title">Interventions de professionnels</h3>
            </div>
            <div className="service__middle">
              <p className="text__muted description">
                Mensuellement, nous invitons des experts du secteur pour partager leurs connaissances et leurs expériences. Ces sessions permettent à nos membres d'acquérir des insights précieux et de se préparer aux défis du monde entrepreneurial.
              </p>
            </div>
          </article>
          {/* End Interventions de professionnels */}

          <article className="service" style={{ '--color-primary': 'blueviolet' }}>
            <div className="service__top">
              <div className="icon__container">
                <FaNetworkWired className="icon" /> {/* Changed icon to FaNetworkWired for community/network */}
              </div>
              <h3 className="title">Soutien continu</h3>
            </div>
            <div className="service__middle">
              <p className="text__muted description">
                Nos services sont conçus pour vous offrir un soutien continu, afin que vous puissiez vous développer tant sur le plan personnel que professionnel, tout en élargissant votre réseau et vos perspectives.
              </p>
            </div>
          </article>
          {/* End Soutien continu */}
        </div>
      </div>
    </section>
  );
};

export default Services;
