import Navbar from "./components/Navbar"
import Header from "./components/Header"
import About from "./components/About"
import Services from "./components/Services"
import Skills from "./components/Skills"
import Projects from "./components/Projects"
import Testimonial from "./components/Testimonial"
import Contact from "./components/Contact"
import Footer from "./components/Footer"

function App() {
  return (
    <>
     <Navbar/>
     <Header id="header"/>   {/* Add the correct ID */}
     <About id="about"/>   {/* Add the correct ID for "À propos" */}
     <Services id="services"/> {/* ID already matches */}
     <Skills id="skills"/>     {/* Add appropriate ID for Skills */}
     <Testimonial id="testimonials"/> {/* Add ID for Testimonials */}
     <Contact id="contacter"/> {/* Add the ID for "Contacter" */}
     <Footer/>
    </>
  );
}


export default App;
