import React, { useRef, useEffect } from 'react';
import './Header.css';
import { profile1 } from '../../images';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import YEN_V2 from '../../images/YEN_V2.MOV';

const Header = () => {
  const container = useRef(null);
  const videoRef = useRef(null);
  gsap.registerPlugin(useGSAP);

  // Play video on user interaction for iOS devices
  useEffect(() => {
    const video = videoRef.current;

    const playVideoOnInteraction = () => {
      if (video && video.paused) {
        video.play().catch(error => {
          console.log('Video playback failed:', error);
        });
      }
    };

    // Listen for user interaction to trigger video playback
    document.addEventListener('touchstart', playVideoOnInteraction);
    document.addEventListener('click', playVideoOnInteraction);

    return () => {
      document.removeEventListener('touchstart', playVideoOnInteraction);
      document.removeEventListener('click', playVideoOnInteraction);
    };
  }, []);

  useGSAP(() => {
    gsap.fromTo(
      '.profile__photo__container',
      {
        scale: 0.5,
        duration: 1,
        opacity: 0.5,
      },
      {
        scale: 1,
        duration: 1,
        ease: 'sine.in',
        opacity: 1,
      }
    );

    gsap.from('.intro__text', {
      fontSize: 100,
      duration: 1,
      delay: 1,
      ease: 'sine.in',
    });

    const timeline = gsap.timeline();
    timeline
      .from('.header__info__top', {
        opacity: 0,
      })
      .from('.header__title', {
        opacity: 0,
        y: -30,
      })
      .from('.header__description', {
        opacity: 0,
      })
      .from('.btn', {
        x: -40,
        opacity: 0,
        stagger: 0.5,
      });
  }, { scope: container });

  return (
    <header id='header' className='blur-effect' ref={container}>
      <video ref={videoRef} className="video-background" autoPlay muted loop playsInline>
        <source src={YEN_V2} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="stroke__text intro__text">YEN</div>
      <div className="section__wrapper header__container">
        <div className="column intro__container blur-effect">
          <div className="header__info">
            <div className="header__info__top">
             Bienvenue à <span className="color__primary">YEN</span>
            </div>
            <div className="header__info__middle">
              <h1 className="primary__title header__title">Young Entrepreneurs Network</h1>
              <p className="text__muted header__description">
              Innovons, réseautons et grandissons ensemble.
              </p>
            </div>
            <div className="header__info__bottom">
              <a href="mailto:contact@yencommunity.ca" className="btn">Contactez-nous</a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
