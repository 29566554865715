import React from 'react'
import "./Testimonial.css"
import{Swiper,SwiperSlide} from 'swiper/react'
import { Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import { profile3,profile4,profile5,profile6 } from '../../images'
const data = [
  {
    name:'Inspiration Sans Limites',
    review:`  Plongez dans un environnement qui stimule l'imagination et l'innovation. Ici, chaque membre est une source d'inspiration, chaque interaction une nouvelle perspective.`
  },
  {
    name:'Savoir Pratique',
    review:` Nos membres sont votre plus grand atout. Construisez des relations stratégiques avec des pairs et des experts prêts à vous soutenir et à co-créer votre succès.`
  },
  {

    name:'Un Réseau Réactif',
    review:` Nos membres sont votre plus grand atout. Construisez des relations stratégiques avec des pairs et des experts prêts à vous soutenir et à co-créer votre succès.`
  },
  {
    
    name:'Résultats Tangibles',
    review:` Mesurez votre succès avec des résultats concrets. Que vous cherchiez à développer vos compétences ou à lancer un projet, notre communauté vous pousse à l’action, pour des résultats durables.`
  }

];

const Testimonial = () => {
  return (
    <section id='testimonial'>
       <div className="section__wrapper">
        <div className="section__header">
          <h2 className="primary__title">Les Clés du Succès</h2>
          <p className="text__muted description">
          Pourquoi rejoindre notre groupe d'entrepreneurs comme YEN ? Un réseau solide est l'un des plus grands 
          atouts en entrepreneuriat. Apprenez, partagez, et établissez des relations durables, peut-être même avec votre futur partenaire ou client clé.
          </p>
        </div>

        <Swiper 
         className='testimonial__container'
         modules={[Pagination]}
         spaceBetween={40}
         slidesPerView={1}
         pagination={{clickable:true}}
         breakpoints={{
          700:{
            slidesPerView:2,
          }
         }}
         >
           {
            data.map(({avatar,name,review},index)=>(
              <SwiperSlide className='testimonial' key={index}>
                <div className="client__avatar">
                  <img src={avatar} alt={review} />
                </div>
                <h3 className="client__name">{name}</h3>
                <small className="client__review">{review}</small>
              </SwiperSlide>
            ))
           }
        </Swiper>
       </div>
    </section>
  )
}

export default Testimonial
